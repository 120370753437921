/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useState } from "react";
import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import review1 from "/assets/img/cheskes.jpg";
import review2 from "/assets/img/galli.jpg";
import review3 from "/assets/img/ivaroni.jpg";
import { robotoSlab } from "/utils/fonts";

const Card = dynamic(() => import("@mui/material/Card"), { loading: () => null });
const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Dialog = dynamic(() => import("@mui/material/Dialog"), { loading: () => null });
const DialogActions = dynamic(() => import("@mui/material/DialogActions"), { loading: () => null });
const DialogContent = dynamic(() => import("@mui/material/DialogContent"), { loading: () => null });
const DialogTitle = dynamic(() => import("@mui/material/DialogTitle"), { loading: () => null });
const Divider = dynamic(() => import("@mui/material/Divider"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const Tooltip = dynamic(() => import("@mui/material/Tooltip"), { loading: () => null });
const PlayCircleFilledRounded = dynamic(() => import("@mui/icons-material/PlayCircleFilledRounded"), { loading: () => null });
const ReviewsRounded = dynamic(() => import("@mui/icons-material/ReviewsRounded"), { loading: () => null });
const MDAvatar = dynamic(() => import('/components/MDAvatar'), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });
const ALLDVideo = dynamic(() => import('/examples/ALLDVideo'), { loading: () => null });
const ALLDBrandsSmall = dynamic(() => import('/examples/ALLDBrandsSmall'), { loading: () => null });

function MiniReviewCard({ author, color, id, review, setSelectedVideoReviewId }) {
  return (
    <MDBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="xl"
      coloredShadow={color === "transparent" ? "dark" : color}
      p={2.5}
    >
      <MDTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={2}
        fontWeight="regular"
      >
        &quot;{review}&quot;
      </MDTypography>
      <MDBox display="flex" alignItems="end" mt={3} sx={{ width: "100%" }}>
        <MDBox display="flex" flexGrow="1" alignItems="center">
          <MDAvatar shadow="md">
            <Image
              src={author.image || null}
              alt={author.name}
              width={48}
              height={48} />
          </MDAvatar>
          <MDBox pl={1.5} lineHeight={1}>
            <MDTypography
              display="block"
              variant="button"
              fontWeight="bold"
              color={color === "transparent" || color === "light" ? "dark" : "white"}
            >
              {author.name}
            </MDTypography>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              color={color === "transparent" || color === "light" ? "text" : "white"}
            >
              {author.date}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Tooltip title="Watch Review">
          <>
            <MDButton
              variant="text"
              iconOnly
              size="large"
              aria-label="Watch review"
              onClick={() => setSelectedVideoReviewId(id)}
              sx={{ fontSize: 80 }}
            >
              <PlayCircleFilledRounded style={{ fontSize: "48px !important" }} color={color === "transparent" ? "info" : "white"} />
            </MDButton>
          </>
        </Tooltip>
      </MDBox>
    </MDBox>
  );
}

function Reviews2({ videoReviews }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [selectedVideoReviewId, setSelectedVideoReviewId] = useState(null);

  return (
    <>
      <MDBox py={6}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            mx="auto"
            sx={{ textAlign: "center" }}
          >
            <MDTypography variant="h2" color={sidenavColor} textGradient className={robotoSlab.className}>
              Trusted By
            </MDTypography>
            <MDTypography variant="h2" mb={2} className={robotoSlab.className}>
              100s of Long Island Home Owners
            </MDTypography>
            <MDTypography variant="body1" color="text" mb={2}>
              Home owners trust Autumn Leaf to deliver.
            </MDTypography>
          </Grid>
          <Grid container spacing={3} mt={controller.mobileView ? 4 : 8}>
            <Grid item xs={12} md={6} lg={4}>
              <MiniReviewCard
                author={{ image: review1.src, name: "Bonnie Cheskes", date: "Greenlawn" }}
                color="transparent"
                id={4}
                review="Lenny's great, very reliable, neat, did a video of the project and the project is exactly how he showed us, it's beautiful, it's functional, we love it!"
                setSelectedVideoReviewId={setSelectedVideoReviewId}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MiniReviewCard
                author={{ image: review2.src, name: "Jill Galli", date: "Huntington" }}
                color="dark"
                id={6}
                review="Blown away at the design, our backyard is more beautiful than we could have ever thought of, beautiful! An unbelievable experience altogether."
                setSelectedVideoReviewId={setSelectedVideoReviewId}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MiniReviewCard
                author={{ image: review3.src, name: "Joe Ivaroni", date: "Commack" }}
                color="transparent"
                id={14}
                review="Very professional, hands-on, every day made sure it was moving forward, helped us avoid pitfalls, put what we had in our mind and made it happen."
                setSelectedVideoReviewId={setSelectedVideoReviewId}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 6, mb: { xs: 4, lg: 0 } }} />
          <ALLDBrandsSmall />
          {controller.mobileView && <br />}
          <MDBox textAlign="center">
              <MDButton
                href="/reviews"
                target="_blank"
                rel="noreferrer noopener"
                variant="contained"
                color="dark"
                startIcon={<ReviewsRounded />}
              >More Reviews</MDButton>
          </MDBox>
        </Container>
      </MDBox>

      <Dialog
        fullWidth
        maxWidth="md"
        open={selectedVideoReviewId !== null}
        onClose={() => setSelectedVideoReviewId(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { backgroundColor: "transparent", overflowY: "visible" } }}
      >
        <Card>
          <DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
            <MDBox sx={{ flexGrow: 1 }}>
              Video Review {selectedVideoReviewId !== null && (
                <>
                  by{' '}
                  {videoReviews.find((obj) => obj.id === selectedVideoReviewId).title}
                </>
              )}
            </MDBox>
            {selectedVideoReviewId !== null && (
              <MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  <span style={{ color: controller.darkMode ? videoReviews.find((obj) => obj.id === selectedVideoReviewId).darkColor : videoReviews.find((obj) => obj.id === selectedVideoReviewId).lightColor }}>{videoReviews.find((obj) => obj.id === selectedVideoReviewId).town}</span>
                </MDTypography>
              </MDBox>
            )}

          </DialogTitle>
          <DialogContent>
            {selectedVideoReviewId !== null && (
              <ALLDVideo row={{
                url: videoReviews.find((obj) => obj.id === selectedVideoReviewId).url,
                title: videoReviews.find((obj) => obj.id === selectedVideoReviewId).reviewer,
                vid: videoReviews.find((obj) => obj.id === selectedVideoReviewId).vid,
              }} />
            )}

          </DialogContent >
          <DialogActions>
            <MDButton
              onClick={() => {
                setSelectedVideoReviewId(null);
              }}>
              Close
            </MDButton>
          </DialogActions>
        </Card>
      </Dialog >
    </>
  );
}

export default Reviews2;
